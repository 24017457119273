import { SxStyleProp } from 'theme-ui';

export const keyboardOutline: SxStyleProp = {
  '@supports selector(:focus-visible)': {
    '&:focus': {
      outline: 'none',
    },
    '&:focus-visible': {
      outline: '3px auto #0374da',
      outlineColor: '-webkit-focus-ring-color',
    },
  },
};

export const keyboardOutlineWithin: SxStyleProp = {
  '&:focus-within': {
    outline: '3px auto #0374da',
    outlineColor: '-webkit-focus-ring-color',
  },
};
